import $ from 'jquery';
import { FormatDate } from './utilities';

export function StartPayment(url, amount, transactionId, reference, receiptHeaderLines, receiptFooterLines) {
    console.log(FormatDate(new Date(), "HH:mm:ss.fff") + ' KimonoX info, startPayment: ' + amount + ' cents / ' + transactionId + ' / ' + reference);

    if (!transactionId) {
        transactionId = "";
    }
    if (!reference) {
        reference = "";
    }

    var postData = {
        amountCent: parseInt(amount),
        transactionId: transactionId,
        merchantReference: reference,
        receiptHeaderLines: receiptHeaderLines,
        receiptFooterLines: receiptFooterLines
    };

    var postDataString = JSON.stringify(postData);
    console.log(FormatDate(new Date(), "HH:mm:ss.fff") + ' KimonoX info, JSON PostData ' + postDataString);

    return $.ajax(
        {
            type: "POST",
            url: url + 'payware/startpayment',
            data: postDataString
        }
    )
        .done(function (data) {
            console.log(FormatDate(new Date(), "HH:mm:ss.fff") + ' KimonoX info, payment status: ' + data);
            return data;
        })
        .fail(function (xhr, textStatus, errorThrown) {
            console.log(FormatDate(new Date(), "HH:mm:ss.fff") + ' KimonoX error, payment failed: ', xhr.responseText);
            return Error();
        });
};

export function BarcodeStartScanCamera(url, cameraId, timeout) {
    return $.ajax(
        {
            type: "GET",
            url: url + "barcode/startscancamera/" + cameraId + "/" + timeout
        }
    ).done(function (data) {
        console.log(FormatDate(new Date(), "HH:mm:ss.fff") + ' KimonoX info, scanning status: ' + data);
    }).fail(function (error) {
        console.log(FormatDate(new Date(), "HH:mm:ss.fff") + ' KimonoX error, scanning status: ' + error);
    });
};