import './App.scss';
import RoutesContainer from './components/RoutesContainer';
import { ConfigProvider } from './providers/ConfigProvider';
import { TextAndLanguageProvider } from './providers/TextAndLanguageProvider';
import remote from 'loglevel-plugin-remote';
import apisettings from './apiSettings';
import log from 'loglevel';

const App = () => {
  const customJSON = log => ({
    msg: log.message,
    level: log.level.label,
    stacktrace: log.stacktrace
  });
  log.setLevel(log.levels.INFO, true);
  remote.apply(log, { format: customJSON, url: apisettings.BASE_URL + '/Logger', json: true });
  log.info('starting kiosk app');
  return (
    <ConfigProvider>
      <TextAndLanguageProvider>
        <RoutesContainer />
      </TextAndLanguageProvider>
    </ConfigProvider>
  );
}

export default App;
