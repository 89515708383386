import React, { useEffect, useContext } from 'react';
import awsconfig from '../awsSettings';
import i18n from '../utilities/i18n';
import { ConfigContext } from './ConfigProvider';

export const TextAndLanguageProvider = ({ children }) => {
    const { CustomerConfig, KioskConfig, EnabledFeatures } = useContext(ConfigContext);

    useEffect(() => {
        if (CustomerConfig.Customer) {
            async function fetchTextAndLanguage() {
                let language = 'nl';

                fetch(`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/text/kiosk/texts-${language}.json`)
                    .then(data => data.json())
                    .then(data => {
                        i18n.addResourceBundle(language, 'translation', data);
                        i18n.changeLanguage(language);
                    })
                    .catch(error => { console.log(error) });
            }

            fetchTextAndLanguage();
        }
    }, [CustomerConfig.Customer])

    return (
        <ConfigContext.Provider value={{ KioskConfig, CustomerConfig, EnabledFeatures }}>
            {children}
        </ConfigContext.Provider>
    )
}

export const useTextAndLanguage = () => useContext(ConfigContext);