import React, { useEffect, useState, useContext } from 'react';
import apisettings from '../apiSettings';

export const ConfigContext = React.createContext();

export const ConfigProvider = ({ children }) => {
    const [KioskConfig, setKioskConfig] = useState([]);
    const [CustomerConfig, setCustomerConfig] = useState([]);
    const [EnabledFeatures, setEnabledFeatures] = useState([]);

    useEffect(() => {
        async function fetchKioskConfig() {
            fetch(apisettings.BASE_URL + "/GetConfig")
                .then(data => data.json())
                .then(data => setKioskConfig(data))
                .catch(error => { console.log(error) });
        }

        async function fetchCustomerConfig() {
            fetch(apisettings.BASE_URL + "/WhoAmI")
                .then(data => data.json())
                .then(data => { setCustomerConfig(data); setEnabledFeatures(data.EnabledFeatures); })
                .catch(error => { console.log(error) });
        }

        fetchKioskConfig();
        fetchCustomerConfig();
    }, [])

    return (
        <ConfigContext.Provider value={{ KioskConfig, CustomerConfig, EnabledFeatures }}>
            {children}
        </ConfigContext.Provider>
    )
}

export const useConfig = () => useContext(ConfigContext);
