export function updateCloudLockers(lockersToUpdate) {
  let CGLockers = lockersToUpdate.map(x => {
    return Object.assign({}, x, {
      // ProductId: null,
      IsSoldOut: true,
      ReplenishedAt: null,
      Enabled: false
    });
  });
  return CGLockers;
}

export function checkIfCombiDealProductsAreAvailable(productsTotal, cartContents, combiDeal) {
  let areProductsAvailable = true;
  let productsNotAvailable = [];

  const product1Id = combiDeal.Product1Id;
  const product2Id = combiDeal.Product2Id;

  let Product1totalAmount;
  let Product2totalAmount;

  if (productsTotal.find(p => p.Id === product1Id)) {
    Product1totalAmount = productsTotal.find(p => p.Id === product1Id).Amount;
  }
  if (productsTotal.find(p => p.Id === product2Id)) {
    Product2totalAmount = productsTotal.find(p => p.Id === product2Id).Amount;
  }

  let product1Incart;
  let product2Incart;

  if (cartContents.find(p => p.ProductId === product1Id)) {
    product1Incart = cartContents.find(p => p.ProductId === product1Id);
  }
  if (cartContents.find(p => p.ProductId === product2Id)) {
    product2Incart = cartContents.find(p => p.ProductId === product2Id);
  }

  if (product1Incart) {
    if (product1Incart.Amount >= Product1totalAmount) {
      const p1Title = productsTotal.find(p => p.Id === product1Incart.ProductId).Title;
      productsNotAvailable.push(p1Title);
    }
  }

  if (product2Incart) {
    if (product2Incart.Amount >= Product2totalAmount) {
      const p2Title = productsTotal.find(p => p.Id === product2Incart.ProductId).Title;
      productsNotAvailable.push(p2Title);
    }
  }

  if (productsNotAvailable.length) {
    areProductsAvailable = false;
  }

  return { areProductsAvailable, productsNotAvailable };
}

export function checkIfSingleItemCanBeAdded(productsTotal, cartContents, productId) {
  let canBeAdded = true;

  if (cartContents.length) {

    const productToAdd = cartContents.find(p => p.ProductId === productId);
    if (productToAdd) {

      const product = productsTotal.find(p => p.Id === productToAdd.ProductId);

      if (productToAdd.Amount >= product.Amount) {
        canBeAdded = false;
      }
    }
  }

  return { canBeAdded }
}