import { makeStyles } from '@material-ui/core/styles';
import { useConfig } from '../providers/ConfigProvider';
import awsconfig from '../awsSettings';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "1200px",
    overflowY: "auto",

    '& .cart-item-outer': {
      marginTop: "20px",
      borderTop: "1px solid #ccc",
      padding: "10px 0"
    },
    '& .cart-item': {
      borderTop: "1px solid #ccc",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 10px 0 0"
    },
    '& .cart-item img': {
      width: "60px"
    },
    '& .cart-item-images': {
      display: "flex",
      flexDirection: "column"
    },
    '& .cart-item-details': {
      width: "180px",
      padding: "0 3px",
     },
    '& .cart-item-labels>*': {
      background: "#FFF0B3",
      fontWeight: 700,
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "2px",
      marginRight: "5px",
    },
    '& .item-title': {
      margin: "5px 0",
      fontSize: "14px"
    },
    '& .cart-item-price': {
      fontSize: "20px",
      fontWeight: 700
    },
    '& .cart-amount-handler': {
      marginTop: "20px",
      justifyContent: "center",
    },
    '& .cart-amount-handler .MuiButtonBase-root': {
      minWidth: "70px",
      fontSize: "24px",
      padding: "0!important",
      height: "70px"
    },
    '& .cart-amount-handler .MuiButtonBase-root .MuiButton-label': {
      paddingBottom: "5px",
      fontSize: "57px"
    },
    '& .cart-amount-handler .MuiTextField-root': {
      width: "70px",
      height: "70px",
      margin: "0 10px",
      background: "white",

    },
    '& .cart-amount-handler .MuiTextField-root input': {
      width: "60px",
      height: "53px",
      fontSize: "24px",
      textAlign: "center"
    },
    '& .cart-item-price-nodiscount': {
      fontSize: "20px",
      color: "#CCCCCC",
      textDecoration: "line-through",
      fontWeight: 700,
      textAlign: "right",
      marginTop: "15px"
    },
    '& .cart-item-price-discount': {
      fontSize: "24px",
      color: "#BE1622",
      fontWeight: 700
    },
    '& .remove-icon': {
      marginTop: "35px",
      marginRight: "15px"
    },
    '& .remove-icon img': {
      width: "35px",
      opacity: 0.4
    },
    '& .additionalDiscount': {
      padding: "10px",
      color: "#BE1622",
      fontSize: "10px",
    }    
  },
}));

function ChallengeSpendingPromotion(props)
{
    const { CustomerConfig } = useConfig();
    const classes = useStyles();
    return (
      <div id="spendingchallenge" className={classes.root}>
        {props.offers.map((offer, index) =>
        { return (
          offer.visible ? <div key={index}>
              <div className="cart-item">
                {CustomerConfig.Customer ?
                  <div className="cart-item-images">
                      <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${offer.Id}_${offer.Avatar}`} alt={offer.Title} />
                  </div>
                  : null}
                <div className="cart-item-details flex-col">
                  <div className="cart-item-labels flex">
                    {offer.IsFreeProduct ? <span>Gratis</span> : <span>Extra korting</span>}
                  </div>
                  <div className="item-title">
                    <h3 className="m0">{offer.Title}</h3>
                  </div>
                  <div className="item-title">
                    <h3 className="m0">Vanaf €{offer.MinimumSpending.toFixed(2).replace(".", ",")}</h3>
                  </div>
                </div>
                <div className="additionalDiscount cart-item-price-outer">
                  {
                    offer.applied ?
                    <div><h2>-€{offer.appliedDiscount.toFixed(2).replace(".", ",")}</h2></div>
                    :
                    <div><h2>-€0,00</h2></div>
                  }
                {false && CustomerConfig.Customer ?
                  <div className="remove-icon" onClick={(e) => props.toggleNoThanksItemByIndex(index)}>
                    <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-remove-icon.png`} alt="" />
                  </div>
                  : null}
                </div>
              </div>
        </div> 
        : null)})
                }
                </div>)
}

export default ChallengeSpendingPromotion;