import React from 'react'
// import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {

    width: "100%",
    height: "100%",
    background: "#00973B",
    position: "absolute",
    top: 0,
    left: 0,
    textAlign: "center",
    paddingTop: 350,

    '& .modal': {
      padding: "48px 24px",
      background: "#fff",
      height: "auto",
      width: "508px",
      borderRadius: "12px",
      boxShadow: "0 0 1px 0 rgba(0,0,0,0.3), 0 4px 8px -2px rgba(0,0,0,0.25)",
      textAlign: "center",
      margin: "0 auto"
    },

  },
}));

function ShoppingErrorScreen(props) {
  // const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="modal">
        <h1 className="main-title">Sorry er is iets mis gegaan 😔</h1>
        <Button
          style={{ marginTop: 50, width: 300 }}
          type="button"
          className="darkgreen-btn"
          variant="contained"
          color="primary"
          onClick={() => props.goBack()}>Doorgaan met winkelen</Button>
      </div>
    </div>
  )
}

export default ShoppingErrorScreen;
