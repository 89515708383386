/* Config file for aws authentication */
const awsconfig = {
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_WEBDEPLOYBUCKET,
      region: "eu-west-1",
    }
  }
};

export default awsconfig;
