import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FullScreen from '../components/FullScreen';
import apisettings from '../apiSettings';
import QRCode from "react-qr-code";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "#00973B",
        justifyContent: "center",
        alignItems: "center",

        '& .modal': {
            padding: "48px 24px",
            background: "#fff",
            height: "auto",
            width: "508px",
            borderRadius: "12px",
            boxShadow: "0 0 1px 0 rgba(0,0,0,0.3), 0 4px 8px -2px rgba(0,0,0,0.25)",
            textAlign: "center"
        },
        '& .main-title': {
            fontSize: "35px"
        },
        '& .sub-title': {
            fontSize: "24px",
            marginTop: "5px",
        },
        '& .bottom-text': {
            fontSize: "20px",
            marginTop: "50px",
        },

        '& .MuiButtonBase-root ': {
            width: "100%",
            padding: "32px 66px",
            marginTop: "20px",
            fontSize: "20px",
            textTransform: "none"
        }
    },
}));

function PaywareSuccess() {
    const classes = useStyles();
    const { t } = useTranslation();

    const [SendTransactionSuccess, setSendTransactionSuccess] = useState(true);
    const [ReceiptUrl, setReceiptUrl] = useState(null);

    const localGateway_GetHardwareStatus = useCallback(() => {
        window.fetch(apisettings.BASE_URL + "/GetHardwareStatus")
            .then(response => response.json())
            .then(data => {
                console.log(data);
                console.log(data.Source);
                var message = "";
                console.log(data.DispensationStatus);
                switch (data.MessageId) {
                    case "OpenLockers":
                        message = "De lockers worden geopend";
                        break;
                    case "OpenLockerStart":
                        message = "De locker voor " + data.Product + " wordt geopend";
                        break;
                    case "OpenLockerSuccess":
                        message = "De locker voor " + data.Product + " is succesvol geopend";
                        break;
                    case "OpenLockerFailed":
                        message = "De " + data.Product + " kon niet worden uitgegeven";
                        break;
                    case "DispenseStart":
                        message = "Er worden " + data.Total + " producten geleverd uit de verkoopautomaat";
                        break;
                    case "DispenseSuccess":
                        message = "De " + data.Product + " is succesvol uitgegeven van rij " + data.PhysicalRow + ", kolom " + data.PhysicalSpiral;
                        break;
                    case "DispenseFailed":
                        message = "De uitgifte van de " + data.Product + " uit de verkoopautomaat van rij " + data.PhysicalRow + ", kolom " + data.PhysicalSpiral + " is mislukt";
                        break;
                    case "DispensationStatusMessage":
                        switch (data.DispensationStatus) {
                            case "DispensationReceived":
                                message = "De uitgifte van de " + data.Product + " uit de verkoopautomaat van rij " + data.PhysicalRow + ", kolom " + data.PhysicalSpiral + " wordt gestart";
                                break;
                            case "DispensationInCourse":
                                message = "De uitgifte van de " + data.Product + " uit de verkoopautomaat van rij " + data.PhysicalRow + ", kolom " + data.PhysicalSpiral + " wordt uitgevoerd";
                                break;
                            case "WaitingForProductToBeCollectedByUser":
                                message = "De " + data.Product + " kan worden uitgenomen uit de verkoopautomaat";
                                break;
                            case "ProductCollectedDispensationSuccess":
                                message = "De " + data.Product + " is uitgenomen uit de verkoopautomaat";
                                break;
                            case "DispensationNotPerformed":
                            case "TokenNotFound":
                            case "DispensingQueueFull":
                                message = "De uitgifte van de " + data.Product + " uit de verkoopautomaat van rij " + data.PhysicalRow + ", kolom " + data.PhysicalSpiral + " is mislukt";
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                if (message && message !== "") {
                    var elem = document.getElementById('deliveryMessage');
                    if (elem) {
                        elem.innerHTML = message;
                    }
                }
            })
            .catch(error => {
                console.log(error);
                // setShowErrorModal(true);
            });
        setTimeout(function () { localGateway_GetHardwareStatus(); }, 1000);
    }, []);

    useEffect(() => {
        // Put request to Local gateway api (payment was successfull)
        console.log("Payment Success!")
        localGateway_PaymentSuccessfull();
        setReceiptUrl(localStorage.getItem("ReceiptUrl"));
        setTimeout(function () { localGateway_GetHardwareStatus(); }, 1000);
    }, [localGateway_GetHardwareStatus]);

    async function localGateway_PaymentSuccessfull() {
        var req = localStorage.getItem("SendTransactionReqBody");

        var xhr = new XMLHttpRequest();

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                console.log(this);
                // check on responseText (fallback for any errors after Transaction has been sent)
                if (this.responseText) {
                    setSendTransactionSuccess(false);
                }
                else {
                    console.log('Transaction sent');
                    setSendTransactionSuccess(true);
                    // set timeout, this way the customer has at least 20 seconds to read the 'transaction voltooid' screen
                    setTimeout(function () { document.location.href = '/index.html#/newsession' }, 20000);
                }
            }
        });

        xhr.open("PUT", apisettings.BASE_URL + "/SendTransaction");
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.send(req);
    }
    
    return (
        <FullScreen>
            <div id="PaywareSuccess" className={classes.root}>
                {SendTransactionSuccess ?
                    <div className="modal">
                        <h1 className="main-title">Transactie voltooid</h1>
                        {ReceiptUrl ?
                            <>
                                <h3 className="sub-title">Scan de onderstaande QR code indien je graag een digitale bon wilt.</h3>
                                <QRCode value={ReceiptUrl} style={{ margin: '10px' }} />
                            </>
                            :
                            <h3 className="sub-title">We hebben helaas geen digitale bon kunnen genereren (QR code).</h3>
                        }
                        <h3 className="sub-title">De gekochte producten worden op volgorde uitgeleverd.</h3>
                        <h3 className="sub-title">De lockers zullen gaan knipperen en je kunt je producten uitnemen.</h3>

                        <h1 className="main-title" id="deliveryMessage"> </h1>

                        <h4 className="bottom-text">
                            Heb je een vraag of probleem?<br />{t('contact.optionandname')}
                        </h4>
                        <h3 className="sub-title">{t('contact.optiondetails')}</h3>
                    </div>
                    :
                    <div className="modal">
                        <h1 className="main-title">Product is niet uitgegeven 😔</h1>
                        {ReceiptUrl ?
                            <>
                                <h3 className="sub-title">Scan de onderstaande QR code indien je graag een digitale bon wilt.</h3>
                                <QRCode value={ReceiptUrl} style={{ margin: '10px' }} />
                            </>
                            :
                            <h3 className="sub-title">We hebben helaas geen digitale bon kunnen genereren (QR code).</h3>
                        }
                        <h3 className="sub-title">Het lijkt er op dat jouw product niet is uitgegeven.</h3><br />
                        <h4 className="bottom-text">
                            Heb je een vraag of probleem?<br />{t('contact.optionandname')}
                        </h4>
                        <h3 className="sub-title">{t('contact.optiondetails')}</h3>
                        <h3 className="sub-title">Wij lossen het graag voor jou op.</h3>
                        <Button
                            type="button"
                            className="darkgreen-btn"
                            variant="contained"
                            color="primary"
                            onClick={() => document.location.href = '/index.html#/newsession'}>Volgende klant
                        </Button>
                    </div>
                }
            </div>
        </FullScreen>
    )
}

export default PaywareSuccess
