import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import FullScreen from '../components/FullScreen';
import { MakeID, FormatDate } from '../utilities/utilities';
import { StartPayment } from '../utilities/kimono-x';
import { useConfig } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {

    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: "#00973B",
    justifyContent: "center",
    alignItems: "center",

    '& .modal': {
      padding: "48px 24px",
      background: "#fff",
      height: "auto",
      width: "508px",
      borderRadius: "12px",
      boxShadow: "0 0 1px 0 rgba(0,0,0,0.3), 0 4px 8px -2px rgba(0,0,0,0.25)",
      textAlign: "center"
    },
    '& .main-title': {
      fontSize: "35px"
    },
    '& .sub-title': {
      fontSize: "24px",
      marginTop: "5px",
    },
    '& .bottom-text': {
      fontSize: "20px",
      marginTop: "50px",
    },

    '& .MuiButtonBase-root ': {
      width: "100%",
      padding: "32px 66px",
      marginTop: "20px",
      fontSize: "20px",
      textTransform: "none"
    }
  },
}));

function PaywareTerminalInstructions() {
  const classes = useStyles();
  const { KioskConfig } = useConfig();
  const { t } = useTranslation();

  useEffect(() => {
    const amountToPay = localStorage.getItem("PriceTotalCents");
    localStorage.removeItem("PriceTotalCents");

    if (amountToPay != null) {
      console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'payment started, amount (in cents): ', amountToPay);
      startPayment(amountToPay);
    }
    else {
      console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'payment not started, amountToPay is null');
      document.location.href = '/#/payware/failure';
    }
    // eslint-disable-next-line
  }, []);

  function startPayment(amountToPay) {

    var transactionId = MakeID(10);
    var merchantReference = MakeID(15);

    StartPayment("http://" + KioskConfig.PaymentTerminalIp + ":9082/api/kimono/", amountToPay, transactionId, merchantReference, ["header 1", "header 2"], ["footer A", "footer B"])
      .then(response => {
        if (typeof response.success != 'undefined' && response.success) {
          if (response.success === 1) {
            // successfull payment
            console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'KimonoX info, successfull payment: ', response.successText);

            // retrieve e-receipt url
            if (response.eReceiptUrl) {
              localStorage.removeItem("ReceiptUrl");
              localStorage.setItem("ReceiptUrl", response.eReceiptUrl.length > 0 ? response.eReceiptUrl : '');
            }

            document.location.href = '/#/payware/success';
          }
          else {
            // unsuccessfull payment
            console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'KimonoX error, unsuccessfull payment: ', response.errorText);
            document.location.href = '/#/payware/failure';
          }
        }
        else {
          // handle errors
          console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'KimonoX error, unhandled: ', response.errorText);
          document.location.href = '/#/payware/failure';
        }
      })
      .catch(error => { 
        console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'KimonoX error3, error: ', error);
        document.location.href = '/#/payware/failure';
    });
  };

  return (
    <FullScreen>
      <div id="PaywareTerminalInstructions" className={classes.root}>

        <div className="modal">
          <h1 className="main-title">Volg de instructies op de betaalautomaat</h1>
          <h3 className="sub-title">De gekochte producten worden na betaling op volgorde uitgeleverd.</h3>
          <h3 className="sub-title">De lockers zullen gaan knipperen en je kunt je producten uitnemen.</h3>
          <h3 className="sub-title"><i>Ben je iets vergeten? Gebruik dan de rode knop op de betaalautomaat om de betaling te annuleren.</i></h3>
          <h4 className="bottom-text">
            Heb je een vraag of probleem?<br />{t('contact.optionandname')}
          </h4>
          <h3 className="sub-title">{t('contact.optiondetails')}</h3>
        </div>
      </div>
    </FullScreen>
  )
}

export default PaywareTerminalInstructions

