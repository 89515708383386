import React from 'react';
import awsconfig from '../awsSettings';
import { makeStyles } from '@material-ui/core/styles';
import { useConfig } from '../providers/ConfigProvider';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    '& .header, .footer': {
      display: "flex",
      fontSize: "34px",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: 700
    },
    '& .header': {
      margin: "20px 0 10px 10px",
    },
    '& .product-flex-container': {
      flex: 1,
      maxHeight: "1630px",
      overflowY: "auto",
      alignContent: "flex-start"
    },
    '& .footer': {
      margin: "30px 0 0 10px",
    },

  },
}));

function PromotionsCategory(props) {
  const classes = useStyles();
  const { CustomerConfig } = useConfig();

  function getTotalPrice(offer) {
    let totalPrice;
    if (offer.IsCombination) {
      if (offer.ProductsInOffer.length > 1) {
        totalPrice = offer.ProductsInOffer[0].Product.DefaultPrice + offer.ProductsInOffer[1].Product.DefaultPrice
      } else {
        // n+1 offer
        totalPrice = offer.ProductsInOffer[0].Product.DefaultPrice * (offer.ProductsInOffer[0].OfferSameProductUnits + 1)
      }
    } else {
      totalPrice = offer.ProductsInOffer[0].Product.DefaultPrice;
    }
    return totalPrice;
  }

  function getDiscountPrice(offer) {
    let discountedPrice;
    const product1 = offer.ProductsInOffer[0].Product;
    let product2;

    if (offer.ProductsInOffer.length > 1) {
      product2 = offer.ProductsInOffer[1].Product;
    }

    if (offer.ProductsInOffer[0].ProductFixedPrice) {
      discountedPrice = offer.ProductsInOffer[0].ProductFixedPrice;
    } else {
      if (offer.ProductsInOffer.length > 1) {
        let combinedPrice = product1.DefaultPrice + product2.DefaultPrice;
        discountedPrice = combinedPrice - (combinedPrice * offer.ProductsInOffer[0].ProductDiscount);
      } else {
        // handle (1+1) here too
        discountedPrice = product1.DefaultPrice - (product1.DefaultPrice * offer.ProductsInOffer[0].ProductDiscount);
      }
    }
    return discountedPrice;
  }

  function selectPromotionItem(offer) {
    if (offer.IsCombination) {
      if (!props.cartContents.find(i => i.OfferId === offer.Id)) {
        const combiDeal = [];
        const offerItem = props.createCombiItem(offer);
        combiDeal.push(offerItem);
        props.addCombiCartItem(0, combiDeal);
      } else {
        props.handleToastMsg("Product is al toegevoegd aan jouw winkelmandje");
      }
    } else {
      if (!props.cartContents.find(i => i.ProductId === offer.ProductsInOffer[0].ProductId)) {
        props.createSingleCartItem(offer.ProductsInOffer[0].ProductId)
      } else {
        props.handleToastMsg("Product is al toegevoegd aan jouw winkelmandje");
      }
    }

  }

  return (
    <div className={classes.root}>
      {CustomerConfig.Customer ?
        <div className="header" onClick={() => { props.backToMain(); props.resetTimer(); }}>
          <img className="mr1" src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-backarrow-icon.png`} width="50" alt="back" /> Aanbiedingen
        </div>
        : null}
      <div className="product-flex-container">
        {props.offers.map(offer => {
          return (
            <div key={offer.Id} className="product product-flex-item flex-col" onClick={() => selectPromotionItem(offer)} >
              {CustomerConfig.Customer ?
                <div className="image-container">
                  <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${offer.Id}_${offer.Avatar}`} alt={offer.Title} />
                </div>
                : null}
              <div className="product-details flex-col">
                <div className="product-item-labels">
                  {offer.ProductsInOffer.length > 1 || offer.ProductsInOffer[0].OfferSameProductUnits ? <span>Combi deal</span> : null}
                </div>
                <p className="panel-product-title">{offer.Title}</p>
                <p className="panel-product-weight" style={{ margin: 0 }}>
                  {props.getAmountOrWeight(offer.ProductsInOffer[0].ProductId)}
                  {
                    offer.ProductsInOffer.length > 1 ?
                      <>&<br />{props.getAmountOrWeight(offer.ProductsInOffer[1].ProductId)}</> :
                      offer.ProductsInOffer[0].OfferSameProductUnits ? <> x {offer.ProductsInOffer[0].OfferSameProductUnits + 1}</> : null
                  }
                </p>
              </div>

              <div className="product-price-info flex-reverse">
                <div className="flex-align-center">
                  <div className="product-price-nodiscount">€{getTotalPrice(offer).toFixed(2).replace(".", ",")}</div>
                  <div className="product-price-discount">€{getDiscountPrice(offer).toFixed(2).replace(".", ",")}</div>
                </div>
              </div>

            </div>
          )
        })}
      </div>
      {CustomerConfig.Customer ?
        <div className="footer" onClick={() => { props.backToMain(); props.resetTimer(); }}>
          <img className="mr1" src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-backarrow-icon.png`} width="50" alt="back" /> Terug naar categorieën
        </div>
        : null}
    </div>
  )
}

export default PromotionsCategory
