import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FullScreen from '../components/FullScreen';
import awsconfig from '../awsSettings';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../providers/ConfigProvider';
import log from 'loglevel';

const useStyles = makeStyles(theme => ({
  root: {

    paddingTop: "150px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    background: "#f4f4f4",

    '& #logo': {
      width: "300px",
      display: "block",
      margin: "0 auto",
    },
    '& .main-title': {
      fontSize: "56px"
    },
    '& .sub-title': {
      fontSize: "30.5px",
      marginTop: "5px",
      paddingRight: "6px"
    },
    '& .start-button': {
      width: "300px",
      alignSelf: "center",
    },
    '& label': {
      marginLeft: theme.spacing(1),
      marginBottom: '0',
      fontSize: '0.8rem',
      textTransform: "uppercase"
    },
    '& .bottom-image': {
      position: "absolute",
      bottom: 0,
      width: "100%",
    },
    '& #startBottomImage': {
      width: "800px",
      display: "block",
      margin: "0 auto",
    }
  },
}));

// URS-285 - 230911 - reload page if local gateway not reachable for a period of time
function refreshPage() {
  window.location.reload();
}

function Start() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const { KioskConfig, CustomerConfig, EnabledFeatures } = useConfig();
  var [ReloadCountInSeconds, setReloadCountInSeconds] = useState(0);

  useEffect(() => {
    var timer = setInterval(()=>setReloadCountInSeconds(ReloadCountInSeconds + 1), 1000 )
    return function cleanup() {
        clearInterval(timer)
    }
});   



  useEffect(() => {
    const url = document.location.href;
    if (url.substr(url.length - 3) !== "/#/") {
      document.location.href = "/#/";
    } else {
      if (localStorage.getItem("ShoppingAutoRouting")) {
        history.push("/shopping-screen");
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <FullScreen>
      <div id="StartScreen" className={classes.root}>
        {CustomerConfig.Customer ?
          <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-startscreen-logo.png`} alt="" id="logo" draggable={false} />
          : null}
        {/* TODO: Replace below text with generic text */}
        <h1 className="text-center gray main-title mb0 bold">{t('start.customername')}</h1>
        <h2 className="text-center sub-title"><span className="green">{t('start.customer-slogan-1')}</span><span className="gray">{t('start.customer-slogan-2')}</span>
        </h2>
        {KioskConfig.StoreId && CustomerConfig.Customer ?
          <Button
            type="button"
            className="green-btn kiosk-lg-btn start-button"
            variant="contained"
            color="primary"
            onClick={() => {
              log.debug("start shopping");
              history.push("/shopping-screen")
            }
            }
          >Start met winkelen
          </Button>
          : <h2 className="text-center sub-title"><span className="gray">Moment, de winkel wordt geladen...</span></h2>
        }

        {!(KioskConfig.StoreId && CustomerConfig.Customer) && ReloadCountInSeconds > 20 ? refreshPage() : null}

        {/* Only show in development */}
        {KioskConfig.StoreId && CustomerConfig.Customer && process.env.NODE_ENV === 'development' ?
          <div style={{ padding: '25px 0px 0px 25px' }}>
            <b>Environment:</b> {process.env.NODE_ENV}<br />
            <b>Base URL (API):</b> {process.env.REACT_APP_BASE_URL} <br />
            <b>S3 Bucket:</b> {process.env.REACT_APP_WEBDEPLOYBUCKET} <br />
            <br />
            <b>Kiosk Configuration (GetConfig)</b><br />
            <b>StoreId:</b> {KioskConfig.StoreId}<br />
            <b>PaymentTerminalIp:</b> {KioskConfig.PaymentTerminalIp}
            <br /><br />
            <b>Customer Configuration (WhoAmI)</b><br />
            <b>Customer:</b> {CustomerConfig.Customer}<br />
            <b>Customer Database:</b> {CustomerConfig.CustomerDatabase}<br />
            <b>Enabled Features:</b> {EnabledFeatures}
          </div>
          :
          null
        }

        <div className="bottom-image">
          {CustomerConfig.Customer ?
            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-startscreen-bottom.png`} id="startBottomImage" alt="" draggable={false} />
            : null}
        </div>
      </div>
    </FullScreen>
  )
}

export default Start
