import React from 'react';
import ModalBase from './ModalBase';

function ScannerInstructionsModal(props) {
  return (
    <ModalBase>
      <div id="scannerInstructionModal">
        <div className="header">
          <h1>Hoe werkt het scannen van een QR code voor een aanbieding?</h1>
          <div className="flex scannerInstruction-container">
            <div className="flex-col">
              <ul className="scannerInstruction-list">
                <li>Scan uw code door de QR code te scannen met de camera van de pinterminal.</li>
                <li>Het systeem controleert de QR code.</li>
                <li>De aanbieding wordt aan uw winkelmandje toegevoegd.</li>
              </ul>
              <i>U heeft 30 seconden de tijd voor het scannen van de QR code.</i>
            </div>
            <div className="flex-col">
              <img className="scannerInstruction-image" src="/IM30QRCode.png" alt="QRCode logo"></img>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

export default ScannerInstructionsModal
