import { Route, Switch } from 'react-router-dom';
import ShoppingScreen from '../Screens/ShoppingScreen';
import Start from '../Screens/Start';
import PaywareTerminalInstructions from '../Screens/PaywareTerminalInstructions';
import PaywareSuccess from '../Screens/PaywareSuccess';
import PaywareFailure from '../Screens/PaywareFailure';
import PaywarePaymentFailureRetry from '../Screens/PaywarePaymentFailureRetry';
import PaywarePrinterUnavailable from '../Screens/PaywarePrinterUnavailable';
import PaywareCheckTerminalConnection from '../Screens/PaywareCheckTerminalConnection';
import PaywareNoReceipt from '../Screens/PaywareNoReceipt';
import PaywareReceiptAvailable from '../Screens/PaywareReceiptAvailable';
import NewSession from '../Screens/NewSession';
import { FlagsProvider } from 'flagged';
import { useConfig } from '../providers/ConfigProvider';

function RoutesContainer() {
  const { EnabledFeatures } = useConfig();

  return (
    <FlagsProvider features={EnabledFeatures}>
      <div id="tableContainer">
        <Switch>

          <Route
            exact
            path="/"
            component={Start}
          />

          <Route
            exact
            path="/shopping-screen"
            component={ShoppingScreen}
          />

          <Route
            exact
            path="/payware/terminalinstruction"
            component={PaywareTerminalInstructions}
          />

          <Route
            exact
            path="/payware/success"
            component={PaywareSuccess}
          />

          <Route
            exact
            path="/payware/paymentfailureretry"
            component={PaywarePaymentFailureRetry}
          />

          <Route
            exact
            path="/payware/failure"
            component={PaywareFailure}
          />

          <Route
            exact
            path="/payware/printerunavailable"
            component={PaywarePrinterUnavailable}
          />

          <Route
            exact
            path="/payware/noreceipt"
            component={PaywareNoReceipt}
          />

          <Route
            exact
            path="/payware/receiptavailable"
            component={PaywareReceiptAvailable}
          />

          <Route
            exact
            path="/payware/checkterminalconnection"
            component={PaywareCheckTerminalConnection}
          />

          <Route
            exact
            path="/newsession"
            component={NewSession}
            key={document.location.href}

          />

        </Switch>
      </div>
    </FlagsProvider>
  )
}

export default RoutesContainer
