import React, { useEffect } from 'react'

function NewSession() {
  useEffect(() => {
    localStorage.clear();
    document.location.href = "/#/";
  }, [])

  return (
    <div>
    </div>
  )
}

export default NewSession
