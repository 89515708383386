import React, { useEffect, useState } from 'react'
import awsconfig from '../awsSettings';
import { useConfig } from '../providers/ConfigProvider';

function FeaturedProducts(props) {
  const { CustomerConfig } = useConfig();

  const [NewProducts, setNewProducts] = useState([])
  const [PopularProducts, setPopularProducts] = useState([])

  useEffect(() => {
    console.log(NewProducts);

    const popularProducts = props.products.filter(p => p.IsOnStartPage);
    setPopularProducts(popularProducts);

    const newProducts = [];
    newProducts.push(props.products[props.products.length - 1]);
    newProducts.push(props.products[props.products.length - 2]);
    setNewProducts(newProducts);
    // eslint-disable-next-line
  }, [])

  function getAmountOrWeight(Id) {
    const product = props.products.find(p => p.Id === Id);
    let attr = product.Attributes;
    attr = JSON.parse(attr.replace(/'/g, '"'));

    let amount = attr.Priority === 'Amount' ? attr.Amount : attr.Weight;

    return `${amount} ${attr.Priority === 'Amount' ? 'Stuk(s)' : ''}`;
  }

  return (
    <div>
      <div className="product-flex-container">
        {props.productCategories.length ?
          props.productCategories.map(cat => {
            return (
              <div key={cat.Id} className="category product-flex-item category-flex-item flex-col"
                onClick={() => { props.filterCategory(cat.Id); props.resetTimer(); }}
              >
                {CustomerConfig.Customer ?
                  <div className="image-container">
                    <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/product-categories/catid${cat.Id}_${props.productCategories.find(c => c.Id === cat.Id).Avatar}`} alt={cat.Title} />
                  </div>
                  : null}
                <div className="category-title text-center">
                  <h3 className="mt0">{cat.Title}</h3>
                </div>
              </div>
            )
          })
          : <p></p>}
        {
          props.offers.length ?
            <div
              className="category product-flex-item category-flex-item flex-col"
              onClick={() => {
                props.showPromotions();
                props.resetTimer();
              }}
            >
              {CustomerConfig.Customer ?
                <div className="image-container">
                  <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-offer-icon.jpg`} alt={"Promotions"} />
                </div>
                : null}
              <div className="category-title text-center">
                <h3 className="mt0">Aanbiedingen</h3>
              </div>
            </div>
            : null}
      </div>

      {PopularProducts.length ?
        <div id="popularProducts">
          <h1>Nu populair</h1>
          <div className="product-flex-container">
            {
              PopularProducts.map(p => {
                return (
                  <div key={p.Id} className="product product-flex-item flex-col">
                    {CustomerConfig.Customer ?
                      <div className="image-container" onClick={() => props.checkCombinationDealsAvailable(p.Id)}>
                        {
                          props.hasSinglePromotion(p.Id).Bool ?
                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${props.hasSinglePromotion(p.Id).OfferId}_${props.hasSinglePromotion(p.Id).OfferAvatar}`} alt={props.hasSinglePromotion(p.Id).OfferId} />
                            :
                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/products/pid${p.Id}_${PopularProducts.find(prod => prod.Id === p.Id).Avatar}`} alt={p.Title} />
                        }
                      </div>
                      : null}
                    <div className="product-details flex-col" onClick={() => props.checkCombinationDealsAvailable(p.Id)}>
                      <p className="panel-product-title">{p.Title}</p>
                      <p className="panel-product-weight" style={{ margin: 0 }}>{getAmountOrWeight(p.Id)}</p>
                    </div>
                    <div className="product-price-info">
                      <div onClick={() => props.getInfoProduct(p.Id)} className="info-button">i</div>
                      <div className="product-price">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        : null}
    </div>
  )
}

export default FeaturedProducts
