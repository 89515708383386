import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

// const variantIcon = {
//   success: MdCheckCircle,
//   error: MdError,
//   warning: WarningIcon,
//   info: InfoIcon,
// };

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: "#4caf50",
  },
  error: {
    backgroundColor: "#f44336",
  },
  // info: {
  //   backgroundColor: theme.palette.primary.main,
  // },
  // warning: {
  //   backgroundColor: amber[700],
  // },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    fontSize: 26,
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, onClose, message, ...other } = props;
  // const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes["error"], className)}
      style={{ backgroundColor: "#f44336" }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {/* <Icon className={clsx(classes.icon, classes.iconVariant)} /> */}

          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit"
        // onClick={onClose}
        >
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function CustomSnackbar(props) {
  const classes = useStyles();
  function handleClose(event, reason) {

    // if (reason === 'clickaway') {
    //   return;
    // }

    props.handleClose();
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={true}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <MySnackbarContentWrapper
          // onClose={handleClose}
          // variant={"error"}
          message={props.toastMessage}
          className={classes.margin}
        />
      </Snackbar>
    </div>
  );
}
export default CustomSnackbar;